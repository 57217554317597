import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Calendar, momentLocalizer } from 'react-big-calendar';

import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { messagesCa, messagesEs } from '../../Helpers/calendar-messages-es';
import { CalendarEvent } from './CalendarEvent';
import { CalendarModal } from './CalendarModal';
import { useDispatch, useSelector } from 'react-redux';
import { FinalMesConsultat, FixatgesMesConsultatReset, InfoEntreDatesReset, IniciMesConsultat, recuperarFixatgesEntreDates, recuperarIncidenciaEntreDates, recuperarInfoEntreDates } from '../../actions/configCalendariAction';
import { uiMostraCalendarModal, uiMostraDiaConsultatAction } from '../../actions/ui';
import { useNavigate } from 'react-router-dom';
import { convertirMinutsHores, convertirMinutsMinuts } from '../../Helpers/funcionsDates';
import { MenuPrincipal } from '../ui/MenuPrincipal';
import { UsuariLoginat } from '../ui/UsuariLoginat';
import SpinerLoader from '../../Helpers/SpinerLoader';


export const CalendarScreen = () => {
  
    const localizer = momentLocalizer(moment); // or globalizeLocalizer
    const { calendari } = useSelector(state => state);
    const { llistatIncidenciaMesConsultat } =(calendari);
    const { auth,ui,cp } = useSelector(state => state);
    const dispatch = useDispatch(); //Per despachar accions
    const navegacio = useNavigate();

    const [t, i18n] = useTranslation('generic');
    moment.locale(t('Generics.idiomaDatetime'))
    const [lastView, setLastView] = useState( localStorage.getItem('lastView') || 'month' );
    const consultaDia= moment(new Date())

    let events = [];
    let events2 = [];
    let events3 = [];
    let eventDia = [];
    let computSegons = 0;
    let computHores = 0;
    let computMinuts = 0;
    let horesTreballades = 0;
    let minutsTreballats = 0;
    let horesIncidencia = 0;
    let minutsIncidencia = 0;
    let horesTotals = 0;
    let minutsTotals = 0;
    let horesBossaHores = 0;
    let minutsBossaHores = 0;
    const [selectedDate, setSelectedDate] = useState(new Date()); 


    const evento =[{
        DataInici_IncidenciaFix:moment().toDate(),
        Observacions_IncidenciaFix:'d.NumSetmana',
        DataFi_IncidenciaFix:moment().add(2,'hours').toDate(),
        bgcolor:"#FF7F00"
    }]

    useEffect(() => {
        if(calendari.final!= undefined && calendari.final ){  
            dispatch(recuperarIncidenciaEntreDates(auth.tokenJwt,calendari.inici,calendari.final));
            //dispatch(recuperarFixatgesEntreDates(auth.tokenJwt,calendari.inici,calendari.final));
            //dispatch(recuperarInfoEntreDates(auth.tokenJwt,calendari.inici,calendari.final)); 
            
        }
    }, [ui.incidencia && ui.modalCalendar])




   
        //Mostrem sempre Mes
    const onViewChange = (e) => {
        setLastView(e);
        localStorage.setItem('lastView', e);
    }

    //Quan selec un dia et retorna un obj, fem format i li pasem a DiaConsultat per tindre la data al Redux i poder treballar amb ella
    //mostrem el Modal
    const onSelectSlot = (start) => {
        let dia = moment(start.start).format("YYYY-MM-DD");
        dispatch(uiMostraDiaConsultatAction({dia}));
        dispatch( uiMostraCalendarModal() );
    }

    const onSelectSlot2 = (start) => {
         let dia = moment(start.DataInici_IncidenciaFix).format("YYYY-MM-DD");
         dispatch(uiMostraDiaConsultatAction({dia}));
         dispatch( uiMostraCalendarModal() );
    }

    //Rang de dates del pag actual
    const onRangeChange = (e) => {
        const startDate = moment(e.start);

        // Si el día de la fecha de inicio no es el 1, significa que está en el mes anterior
        if (startDate.date() !== 1) {
            // Si no es el día 1, significa que estamos viendo el mes siguiente
            // Ajustamos para ver el mes siguiente
            startDate.add(1, 'month'); // Sumamos un mes para ir al siguiente
        }

        // Establecer la fecha inicial del estado a la fecha ajustada
        setSelectedDate(startDate.startOf('month').toDate());



        let inici = moment(e.start).format("YYYY-MM-DD");
        let final = moment(e.end).format("YYYY-MM-DD");
        dispatch(IniciMesConsultat(inici));
        dispatch(FinalMesConsultat(final));
        dispatch(FixatgesMesConsultatReset());
        dispatch(InfoEntreDatesReset());
        dispatch(recuperarIncidenciaEntreDates(auth.tokenJwt,inici,final));
        dispatch(recuperarFixatgesEntreDates(auth.tokenJwt,inici,final));
        dispatch(recuperarInfoEntreDates(auth.tokenJwt,inici,final));  
    }
    

    
//Color del event i estil  si entra al if de isMine es el color de fixatges sino d incidencies
    const eventStyleGetter = ( events, start, end, isSelected ) => {
        
        let newStyle  = {
            //color incidencia
            backgroundColor: "#ff9103", //taronja
            borderRadius: '0px',
            opacity: 1,
            display: 'block',
            color: '#2d374b'

        }
        //si no es incidencia
        //no inci no alerta no laborable
        if (!events.isMine && !events.existeixAlerta && events.esLaborable){
            newStyle.backgroundColor = "#00c884" //verd
          }
        if (!events.isMine && !events.existeixAlerta && !events.esLaborable){
            newStyle.backgroundColor = "#FFFFFF"
        }

        //no inci si alerta no laborable
        if (!events.isMine && events.existeixAlerta && events.esLaborable){
            newStyle.backgroundColor = "#e44554" // vermell
            newStyle.color = "#FFFFFF" 
           
          }

          if (!events.isMine && events.existeixAlerta && (events.idTipusAlerta == "1" || events.idTipusAlerta == "2" ) ){
              //1. Festiu Estatal / Local 2. Vacances pròpies del treballador
            newStyle.backgroundColor = "#CCCCCC"
        }
        if (events.dissabte){
            newStyle.backgroundColor = "#00c884" //verd
          }

        return {
            style:newStyle 
        }
    };


    //Bucle per guardar informacio de llistat del rang de dates previament vist
    //Pasem les dates a moment toDate per poder interectuar amb Calendar
    if(calendari !=undefined && calendari.llistatIncidenciaMesConsultat !=undefined){

        events = calendari.llistatIncidenciaMesConsultat.contingut.map((d) =>
   ({ DataInici_IncidenciaFix:moment(d.DataInici_IncidenciaFix).toDate(),
      Observacions_IncidenciaFix:d.Observacions_IncidenciaFix, 
      IdTipusIncidencia_IncidenciaFix:d.IdTipusIncidencia_IncidenciaFix, 
      DataFi_IncidenciaFix:moment(d.DataFi_IncidenciaFix).toDate(),
      isMine:true,
      dissabte:false

    
     
      }))
    }
    if(calendari !=undefined && calendari.llistatFixatgesMesConsultat !=undefined){

        events2 = calendari.llistatFixatgesMesConsultat.contingut.map((d) =>
                
                    ({  DataInici_IncidenciaFix:moment(d.dia).toDate(),
                        DataFi_IncidenciaFix:moment(d.dia).toDate(),
                        existeixAlerta:d.existeixAlerta,
                        esLaborable:d.esLaborable,      
                        idTipusAlerta:d.idTipusAlerta,
                        isMine:false,
                        dissabte: Array.isArray(d.fixatges) && d.fixatges.length > 0 ? true : false,
                })
                )
        
        
    }
     events3 = events.concat(events2);
    let mes="";
        if(calendari.infoFixatgesMesConsultat!=undefined){
            let comput= calendari.infoFixatgesMesConsultat.contingut.computMinutsMes;
            let treballats = calendari.infoFixatgesMesConsultat.contingut.computMinutsFixatge;
            let incidencia = calendari.infoFixatgesMesConsultat.contingut.computMinutsIncidencia;
            let bossaHores = calendari.infoFixatgesMesConsultat.contingut.computMiniutsBossaHores;
            let totals = treballats + incidencia + bossaHores;
            computHores = convertirMinutsHores(comput);
            computMinuts = convertirMinutsMinuts(comput);
            horesTreballades = convertirMinutsHores(treballats);
            minutsTreballats = convertirMinutsMinuts(treballats);

            horesIncidencia = convertirMinutsHores(incidencia);
            minutsIncidencia = convertirMinutsMinuts(incidencia);

            horesBossaHores = convertirMinutsHores(bossaHores);
            minutsBossaHores = convertirMinutsMinuts(bossaHores);

            horesTotals = convertirMinutsHores(totals);
            minutsTotals = convertirMinutsMinuts(totals);

            if(i18n.language =="ca"){
                mes =calendari.infoFixatgesMesConsultat.contingut.mes_ca;
            }else if(i18n.language =="es"){
                mes =calendari.infoFixatgesMesConsultat.contingut.mes_es;
            }

        }
    
        
    return (
        
            <div className='position-relative'>
                <div className='card card-shadow card-login'>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className="card-body">
                                <div className='visible-xs'>
                                     <UsuariLoginat />
                                     <hr />
                                </div>
                                    <MenuPrincipal />
                                   
                                    <h3>{t('Header.Historic')}</h3>
                                    {/* aqui esta el button per navegar a la pagina principal */}
                                    

                                    <div className="calendar-screen">
                                        {calendari.llistatIncidenciaMesConsultat != undefined && calendari.llistatFixatgesMesConsultat != undefined && calendari.infoFixatgesMesConsultat != undefined?
                                        
                                        i18n.language =="ca" ?
                                        
                                        <Calendar
                                            localizer={localizer}
                                            events={events3}
                                            startAccessor="DataInici_IncidenciaFix"
                                            endAccessor="DataFi_IncidenciaFix"
                                            onSelectEvent={onSelectSlot2}
                                            messages={messagesCa}
                                            eventPropGetter={ eventStyleGetter }
                                            onRangeChange={onRangeChange}
                                            selectable={ true }
                                            onView={ onViewChange }
                                            view={ "month" }
                                            views={['month']}
                                            onSelectSlot={ onSelectSlot }
                                            date={selectedDate}
                                            components={{
                                                event: CalendarEvent
                                            }}
                                        />
                                        :
                                        <Calendar
                                            localizer={localizer}
                                            events={events3}
                                            startAccessor="DataInici_IncidenciaFix"
                                            endAccessor="DataFi_IncidenciaFix"
                                            onSelectEvent={onSelectSlot2}
                                            messages={messagesEs}
                                            eventPropGetter={ eventStyleGetter }
                                            onRangeChange={onRangeChange}
                                            selectable={ true }
                                            onView={ onViewChange }
                                            view={ "month" }
                                            views={['month']}
                                            onSelectSlot={ onSelectSlot }
                                            date={selectedDate}
                                            components={{
                                                event: CalendarEvent
                                            }}
                                        />
                                        :<SpinerLoader />}
                                        <CalendarModal /> 
                                    </div>
                            </div>
                        </div>
                        <div className='col-md-4 d-md-flex flex-column bg-soft-primary p-8 p-md-5 bg-comb'>
                        <div className='hidden-xs'>
                        <UsuariLoginat />
                        <hr />
                        </div>
                                    {calendari.infoFixatgesMesConsultat != undefined ?
                                    <>
                                    <h5 className='mt-3'>{mes} {computHores} {t('Generics.hores')} {t('Generics.caracIoY')} {computMinuts} {t('Generics.minuts')}</h5>
                                    <p>{t('ResumHores.horesTreball')} {horesTreballades} {t('Generics.hores')} {t('Generics.caracIoY')} {minutsTreballats} {t('Generics.minuts')}</p>
                                    {horesIncidencia>0||minutsIncidencia>0 ?
                                    <p>{t('ResumHores.justificades')} {horesIncidencia} {t('Generics.hores')} {t('Generics.caracIoY')} {minutsIncidencia} {t('Generics.minuts')}</p>
                                    :null}
                                    {horesBossaHores>0||minutsBossaHores>0 ?
                                     <p>{t('ResumHores.bossaHores')} {horesBossaHores} {t('Generics.hores')} {t('Generics.caracIoY')}  {minutsBossaHores} {t('Generics.minuts')}</p>
                                    :null
                                    }
                                    <p>{t('ResumHores.diaTotal')} {horesTotals} {t('Generics.hores')} {t('Generics.caracIoY')} {minutsTotals} {t('Generics.minuts')}</p>
                                    </>
                                     :<SpinerLoader />}
                                    </div>
                    </div>
                </div>
                <figure className="position-absolute top-0 end-0 zi-n1 d-none d-sm-block mt-n7 me-n10 width4rem">
              <img className="img-fluid" src="../assets/svg/components/pointer-up.svg" alt="Image Description" />
            </figure>
           
            <figure className="position-absolute bottom-0 start-0 d-none d-sm-block ms-n10 mb-n10 width15rem">
              <img className="img-fluid" src="../assets/svg/components/curved-shape.svg" alt="Image Description" />
            </figure>
            </div>
            
        
        
    )
}

