import { Spinner } from "react-bootstrap";

export default function SpinerLoader() {
  return (
    <div class="loader-screen">
    <div style={{ margin: "130px" }}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
    </div>
  );
}