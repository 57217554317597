import React, { useEffect,useMemo,useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {useLocation} from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { JornadaFinalitzada, JornadaIniciada, recuperarFixatgesDiaConsultat, recuperarIncidenciesDiaConsultat } from '../../actions/controlPresenciaActions';
import { TipusFixatge } from '../ControlPresencia/TipusFixatge';
import Moment from 'react-moment'
import 'moment-timezone';
import { TipusIncidencia } from '../ControlPresencia/TipusIncidencia';
import { ResumHoresDiaConsultat } from './ResumHoresDiaConsultat';
import { FormulariIncidencia } from '../Incidencia/FormulariIncidencia';
import { esborrarErrorAction, esborrarErrorOkAction, esborrarOkAction, uiAmagarCalendarModal, uiAmagarDiaConsultatAction, uiAmagarIncidenciesAction, uiAmagarIncidenciesRestaHoresAction, uiAmagarIncidenciesRestaHoresFiJornadaAction, uiMostraIncidenciesAction, uiMostraIncidenciesRestaHoresAction, uiMostraIncidenciesRestaHoresFiJornadaAction } from '../../actions/ui';
import { FormulariIncidenciaRestaHores } from '../Incidencia/FormulariIncidenciaRestaHores';
import { FormulariIncidenciaRestaHoresFiJornada } from '../Incidencia/FormulariIncidenciaRestaHoresFiJornada';
import moment from 'moment-timezone';
import { Spinner } from 'react-bootstrap';
import SpinerLoader from '../../Helpers/SpinerLoader';


export const DetallDiaScreen = (props) => {
    //Per consultar el detall de fixatges / incidencies del dia
    const [t, i18n] = useTranslation('generic');
    let location = useLocation();
    const navigate = useNavigate();
    const { auth } = useSelector(state => state);
    const dispatch = useDispatch(); //Per despachar accions
    const { cp,configUsuari } = useSelector(state => state);
    const { incidenciaDiaConsultat, fixatgeDiaConsultat ,jornadaFinalitzada,jornadaIniciada} = cp;
    const {ui} = useSelector(state => state);
    const {incidencia,diaConsultat,modalCalendar,incidenciaRestaHores,msgOk,incidenciaRestaHoresFiJornada} = ui;
    const [diaConsultatAlerta, setDiaConsultatAlerta] = useState( false );
    const [algunFixatge, setAlgunFixatge] = useState( false );
    const [mesFixatges, setMesFixatges] = useState( false );
    const [defaultTime, setdefaultTime] = useState(true);
    let listItems = "";
    let listInci = "";
    let listBossaHores = "";
    const {dataAvui} = (props); 
   

    useEffect(() => {
      dispatch(recuperarIncidenciesDiaConsultat(auth.tokenJwt,diaConsultat.dia));
      dispatch(recuperarFixatgesDiaConsultat(auth.tokenJwt,diaConsultat.dia));
    }, [auth,diaConsultat.dia,modalCalendar,incidencia,jornadaFinalitzada,jornadaIniciada,incidenciaRestaHores,incidenciaRestaHoresFiJornada]);

    useEffect(() => {
      if(fixatgeDiaConsultat!=undefined){
        if(fixatgeDiaConsultat.esLaborable){
          setDiaConsultatAlerta(fixatgeDiaConsultat.idTipusAlerta);
        }else{
          setDiaConsultatAlerta("capSetm");
        }
        
      if (fixatgeDiaConsultat?.fixatges) {
            setAlgunFixatge(fixatgeDiaConsultat.fixatges.length > 0);
            setMesFixatges(fixatgeDiaConsultat.fixatges.length >= 2);
        }
    }
    }, [fixatgeDiaConsultat])



    const handleChangeIncidencia = () =>{
      
     
      if(incidencia){
  
          dispatch(uiAmagarIncidenciesAction())
  
      }else{
        if(incidenciaRestaHores){
          dispatch(uiAmagarIncidenciesRestaHoresAction())
      }
          dispatch(uiMostraIncidenciesAction())
      }

      if(incidencia)
      {
        dispatch(uiAmagarIncidenciesAction())
      }else
      {
        dispatch(uiMostraIncidenciesAction())
      }
      
      dispatch(uiAmagarIncidenciesRestaHoresFiJornadaAction())
      dispatch(uiAmagarIncidenciesRestaHoresAction())


    }

    const handleChangeIncidencia2 = () =>{
     

    
      if(incidenciaRestaHores)
      {
        dispatch(uiAmagarIncidenciesRestaHoresAction())
      }else
      {
        dispatch(uiMostraIncidenciesRestaHoresAction())
      }
      
      dispatch(uiAmagarIncidenciesRestaHoresFiJornadaAction())
      dispatch(uiAmagarIncidenciesAction())

    }

    const handleChangeObrirFormRestaFiJornada = () =>{
      
      if(incidenciaRestaHoresFiJornada)
      {
        dispatch(uiAmagarIncidenciesRestaHoresFiJornadaAction())
      }else
      {
        dispatch(uiMostraIncidenciesRestaHoresFiJornadaAction())
      }
      
      dispatch(uiAmagarIncidenciesRestaHoresAction())
      dispatch(uiAmagarIncidenciesAction())
      
    }


    //si tenim llista de fixatges guardem en una llista el tipus i la data
    //per el tipus si la psem el numero de fixatge i amb el component TipusFixatge retornem el nom equivalent
if(fixatgeDiaConsultat !== undefined){

    if(fixatgeDiaConsultat.fixatges !== undefined){
     
      //console.log(fixatgeDiaConsultat.fixatges.pop());
      listItems = fixatgeDiaConsultat.fixatges.map((d) => 
      <li className='list-checked-item' key={d.IdFixatge}>
          <TipusFixatge idTipusFixatge={Number(d.IdTipus_Fixatge)} idTipusIncidencia={Number(d.IdTipusIncidencia_Fixatge)} /> - <Moment format="HH:mm" date={d.DataHora_Fixatge} />   
      </li>); 
    }
  }
    const { hores, minuts } = useMemo(() => {
      if (fixatgeDiaConsultat?.fixatges?.length) {
          const lastFixatge = fixatgeDiaConsultat.fixatges[fixatgeDiaConsultat.fixatges.length - 1].DataHora_Fixatge;
          const minuts = moment(lastFixatge).add(1, 'm').format('m');
          const hores = minuts === "0" 
              ? moment(lastFixatge).add(1, 'h').format('H') 
              : moment(lastFixatge).format('H');
          return { hores, minuts };
      }
      return { hores: 7, minuts: 0 };
  }, [fixatgeDiaConsultat]);
    
  

  useEffect(() => {
    setdefaultTime(true);
  }, [fixatgeDiaConsultat])
  
  


  //si tenim llista de incidencia guardem en una llista el tipus i la data
    //per el tipus si la psem el numero d incidencia i amb el component TipusIncidencia retornem el nom equivalent
    //Depenen el valor de valdiat del Responsable mostrem un msg o un altre
  if(incidenciaDiaConsultat !== undefined){
    if(incidenciaDiaConsultat.incidencies !== undefined){
    listInci = incidenciaDiaConsultat.incidencies.map((d) => 
        <li className='list-checked-item' key={d.IdIncidenciaFix}>
            <TipusIncidencia IdTipusIncidencia_IncidenciaFix={Number(d.IdTipusIncidencia_IncidenciaFix)}  /> - 
            {moment(d.DataFi_IncidenciaFix).diff(moment(d.DataInici_IncidenciaFix), 'days')>=1 ?
            <>
            {moment(d.DataInici_IncidenciaFix).format("DD MMM  HH:mm")}<>  -  </>{moment(d.DataFi_IncidenciaFix).format("DD MMM  HH:mm")}   
           </> 
            :<>
            <Moment format="HH:mm" date={d.DataInici_IncidenciaFix} />  - <Moment format="HH:mm" date={d.DataFi_IncidenciaFix} />           
            </>
          }
            {d.ValidatResponsable_IncidenciaFix === "1" && d.Justificat_IncidenciaFix === "1" ?
           <p><span className='badge bg-success'>Validat i justificat</span></p>
            :
                d.ValidatResponsable_IncidenciaFix === "1" && d.Justificat_IncidenciaFix !== "0"  ?
                <p><span className='badge bg-warning'>Validat per responsable</span></p>
                : d.Justificat_IncidenciaFix == "0"?
                <p><span className='badge bg-danger'>No validat</span></p>
                :<p><span className='badge bg-danger'>Pendent de validació</span></p>}
            
            
            
        </li>);
    }
  }

  useEffect(() => {
    if(ui.msgOk !== null){
        
      setTimeout(()=>dispatch(esborrarOkAction()),10000); 
    }    
    if(ui.msgOkError !== null){
        setTimeout(()=>dispatch(esborrarErrorOkAction()),10000);
    } 
    if(ui.msgError !== null){
      setTimeout(()=>dispatch(esborrarErrorAction()),10000);
  } 

  }, [ui.msgOkError,msgOk,ui.msgError])
  
  return (
    <>
     {incidenciaDiaConsultat != undefined || fixatgeDiaConsultat != undefined ?
    <div>
        <h3 className='mt-3 text-aqua'>{t('Header.DetallDia')}  <Moment format="DD/MM/YYYY" date={diaConsultat.dia} /></h3>
        

        {diaConsultatAlerta == "capSetm" && !algunFixatge?
           <p>{t('Calendari.Finde')}</p>
           :diaConsultatAlerta == 1 && !algunFixatge?
           <p>{t('Calendari.DiaFestiu')}</p>
           :diaConsultatAlerta == 2 && !algunFixatge?
           <p>{t('Calendari.Vacances')}</p>
           :<>
        
        <p className="mt-3"><strong>{t('ControlPresencia_LlistatFixatgesDia.title')} <Moment format="DD/MM/YYYY" date={diaConsultat.dia} /> </strong> </p>
        <ul className='list-checked list-checked-soft-bg-primary list-checked-lg mb-6 mt-4'>
            { listItems !="" ?
            listItems
          :<p>{t('Calendari.CapFixatge')}</p>}          
        </ul>
        <hr />
        <h5>{t('FormulariIncidencies.title')}</h5>  
        <ul className='list-checked list-checked-soft-bg-danger mb-6 mt-4'>      
        {listInci !="" ?
          listInci
        :<p className='alert alert-soft-dark'>{t('Calendari.CapIncidencia')}</p>
        }
        </ul>
        <hr />
        <div className='mt-2'>
            { msgOk!=null ? //això es una condició, si es diferent de null
                 <div className="alert alert-success" role="alert">
                {msgOk}
                </div>
              :null
            }
              <p><a href="#!" onClick={handleChangeIncidencia} >{t('FormulariFiIniciJornada.PregIncidencia')} </a></p>
              {  mesFixatges ? <p><a href="#!" onClick={handleChangeIncidencia2} >{t('FormulariFiIniciJornada.PregIncidenciaResta')} </a></p>  : null }
              {  mesFixatges ? <p><a href="#!" onClick={handleChangeObrirFormRestaFiJornada} >{t('FormulariFiIniciJornada.PregIncidenciaRestaFiJornada')} </a></p>  : null }
            {
                incidencia ? <FormulariIncidencia diaConsultat={diaConsultat.dia} hores={hores} minuts={minuts}/> : null
            }
            {
                incidenciaRestaHores ? <FormulariIncidenciaRestaHores diaConsultat={diaConsultat.dia} /> : null
                
            }
            {
                incidenciaRestaHoresFiJornada ? <FormulariIncidenciaRestaHoresFiJornada diaConsultat={diaConsultat.dia} /> : null
            }

            {
                 
            }

            </div>
            <hr />
            <ResumHoresDiaConsultat dataAvui ={dataAvui} />
            <hr />
            </>

          }



            <div className='align-right'>
              <button className='btn btn-primary btn-sm m-1 mb-4' onClick={ () => {
                dispatch(uiAmagarDiaConsultatAction())
                if(incidencia || incidenciaRestaHores){
                  dispatch(uiAmagarIncidenciesRestaHoresAction())
                  dispatch(uiAmagarIncidenciesAction())
                  dispatch(esborrarErrorAction());
                }
                if(ui.modalCalendar){
                  dispatch( uiAmagarCalendarModal() );
                }
                
              }}><i className="bi bi-arrow-left me-2 mb-4"></i> Tornar</button>
            </div>
    </div>
      :<SpinerLoader />}
    </>     
  )
}

